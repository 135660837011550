import React, { useContext } from 'react';
import {Link} from 'react-scroll';
import {useNavigate} from 'react-router-dom';
import './banner.css';
import { RatioContext, realPx, contactUs } from '../../../static/tools/common';
import homeBannerhz from '../../../static/images/opt_home_bannerhz.png';
import homeBtnarow from '../../../static/images/opt_home_btnarow.png';
import lightLeft from '../../../static/images/opt_lightleft.png';
import lightRight from '../../../static/images/opt_lightright.png';
import 'animate.css';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        bHeight: {
            height: realPx(ratio, 666),
        },
        lightLeft: {
            top: 0,
            left: 0,
            width: realPx(ratio, 400),
        },
        lightRight: {
            bottom: realPx(ratio, -100),
            right: 0,
            width: realPx(ratio, 400),
        },
        topBtn: {
            margin: '0 auto',
            padding: `${realPx(ratio, 14)} ${realPx(ratio, 15)}`,
            width: realPx(ratio, 258),
            fontSize: realPx(ratio, 16),
            borderRadius: realPx(ratio, 50),
        },
        info: {
            top: realPx(ratio, 120),
            left: realPx(ratio, 300),
        },
        topBtnIcon: {
            width: realPx(ratio, 22),
        },
        word1: {
            fontSize: realPx(ratio, 56),
            marginTop: realPx(ratio, 30),
        },
        word2: {
            fontSize: realPx(ratio, 56),
            marginTop: realPx(ratio, 34),
            lineHeight: realPx(ratio, 56),
        },
        word3: {
            fontSize: realPx(ratio, 16),
            marginTop: realPx(ratio, 22),
            lineHeight: realPx(ratio, 28),
            width: realPx(ratio, 1032),
            color: 'rgba(255, 255, 255, 0.50)'
        },
        opt: {
            marginTop: realPx(ratio, 56),
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    };
    return (
        <div className="Banner-container" style={style.bHeight}>
            <img className='bgLight' src={lightLeft} style={style.lightLeft} alt="lightLeft" />
            <img className='bgLight' src={lightRight} style={style.lightRight} alt="lightRight" />
            <div className='Banner-info' style={style.info}>
                <div className='Banner-Alert wow animate__fadeInRight' style={style.topBtn}>
                    <img src={homeBannerhz} style={style.topBtnIcon} alt="icon" />
                    <span>Top #1 Marketing Agency</span>
                </div>
                <div className='Banner-word1 wow animate__fadeInLeft' style={style.word1}>Boost your app's growth across various channels</div>
                <div className='Banner-word2  wow animate__fadeInLeft' style={style.word2}>with Opt 360's User Acquisition.</div>
                <div className='Banner-word3  wow animate__fadeInLeft' style={style.word3}>Where Your Advertising Journey Begins. Let Us Empower Your Brand While You Focus On Crafting Exceptional User Experiences.</div>
                <div className='u-Banner-opt Banner-opt-center wow animate__fadeInBottomLeft ' style={style.opt}>
                    {/* <div className='c-btn-hover' style={{ ...style.btn, marginRight: realPx(ratio, 18) }}>
                        <span>Get Started</span>
                        <span className='Banner-opt-icon' style={style.btnImgSpan}>
                            <img src={homeBtnarow} style={style.btnImg} alt="icon" />
                        </span>
                    </div> */}
                    <Link to="nextBlock" smooth duration={500}>
                        <div className="custom-btn-hover Banner-opt-btn" style={{ ...style.btn, marginRight: realPx(ratio, 18) }}>
                            <span>Get Started</span>
                            <span className='Banner-opt-icon' style={style.btnImgSpan}>
                                <img className='custom-btn-hover-img' src={homeBtnarow} style={style.btnImg} alt="icon" />
                            </span>
                        </div>
                    </Link>
                    <div className='custom-btn-hover' style={style.btn} onClick={() => contactUs(navigate)}>Contact Us</div>
                </div>
            </div>
        </div>
    );
}

export default Index;
