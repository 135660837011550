import React, { useContext } from 'react';
import './button.css';
import { RatioContext, realPx } from '../../static/tools/common';
import retargeting from '../../static/images/useracquisition@2x.png';

function Index({ name, icon, style }: any) {
    const ratio = useContext(RatioContext) || 1;
    const localStyle = {
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    }
    return (
        <div className='pramiry' style={{ ...localStyle.btn, ...(style || {}) }}>
            <span>{name || '默认'}</span>
            <span className='pramiry-icon' style={localStyle.btnImgSpan}>
                <img className='custom-btn-hover-img' src={icon || retargeting} style={localStyle.btnImg} alt="icon" />
            </span>
        </div>
    )
}

export default Index;