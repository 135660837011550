import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './nav.css';
import logo from '../../static/images/opt_home_logo.png';
import Menu from '../menu';
import { RatioContext, realPx } from '../../static/tools/common';

function Index() {
    const navigator = useNavigate();
    const location = useLocation();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        navHeight: { height: realPx(ratio, 100) },
        logoHeight: {
            marginTop: realPx(ratio, 31), 
            height: realPx(ratio, 38),
            cursor: 'pointer'
        },
    };
    const backHome =  () => {
        if (location.pathname !== '/') {
            navigator('/')
        }
    }
    return (
        <div className="Nav-container" style={style.navHeight}>
            <div className="Nav-container-child Nav-container_left">
                <img onClick={backHome} style={style.logoHeight} src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="Nav-container-child Nav-container_right"><Menu ratio={ratio} realPx={realPx} /></div>
        </div>
    );
}

export default Index;
