import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './menu.css';
import Seconds from './seconds';
import retargeting from '../../static/images/retargeting@2x.png';
import useracquisition from '../../static/images/useracquisition@2x.png';
import opt_navctvs from '../../static/images/opt_navctvs.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


function Index({ ratio, realPx }: any) {
  const navigate = useNavigate()
  const location = useLocation();
  const style = {
    li: {
      margin: '0 ' + realPx(ratio, 20),
      fontSize: realPx(ratio, 18),
      lineHeight: realPx(ratio, 100)
    },
    dropPanel: {
      width: realPx(ratio, 1920),
      height: realPx(ratio, 120),
      top: realPx(ratio, 100),
      left: 0
    }
  };
  const menus = [{
    name: 'Home'
  }, {
    name: 'Solutions',
    children: [
      {
        name: 'User acquisiton',
        icon: useracquisition
      },
      {
        name: 'Retargeting',
        icon: retargeting
      },
      {
        name: 'CTV',
        icon: opt_navctvs
      }
    ]
  }, {
    name: 'Research'
  }, {
    name: 'About'
  }];

  const [selected, setSelected] = useState('Home');
  const [secondsSelected, setSecondsSelected] = useState('');
  const [inMenu, setInMenu] = useState(false);
  const [inSubMenu, setInSubMenu] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props}
      open={true}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      disableFocusListener
      disableTouchListener
      classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      maxWidth: 700,
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: '0px',
    },
  }));

  const handleSelected = (name: string) => {
    if (name !== 'Solutions') {
      // setSelected(name);
      navigate(name === 'Home' ? '/' : '/' + name);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const pathname = location.pathname;
    if (pathname !== '/About') {
      sessionStorage.removeItem('opt');
    }
    switch (pathname) {
      case '/Research':
        setSelected('Research');
        setSecondsSelected('');
        break;
      case '/About':
        setSelected('About');
        setSecondsSelected('');
        break;
      case '/UserAcquisiton':
        setSelected('Solutions');
        setSecondsSelected('User acquisiton');
        break;
      case '/Retargeting':
        setSelected('Solutions');
        setSecondsSelected('Retargeting');
        break;
      case '/CTV':
        setSelected('Solutions');
        setSecondsSelected('CTV');
        break;
      default:
        setSelected('Home');
        setSecondsSelected('');
        break;
    }
  }, [])

  const handleSecondsSelected = (name: string) => {
    // console.log('CTV', name)
    // setSelected('Solutions');
    // setSecondsSelected(name);
    navigate(name === 'User acquisiton' ? '/UserAcquisiton' : '/' + name);
  };


  return (
    <ul className="Meun-container">
      {
        menus.map((item: any, index: number) => (
          <li
            key={index}
            className={selected === item.name ? 'selected custom-card ' + item.name : item.name + ' custom-card'}
            style={style.li}
            onClick={() => handleSelected(item.name)}
            onMouseEnter={() => index === 1 ? setInMenu(true) : null}
            onMouseLeave={() => index === 1 ? setInMenu(false) : null}
          >
            {item.name}
          </li>
          // <HtmlTooltip
          //   key={index}
          //   open={true}
          //   title={
          //     item.children ?
          //       <Seconds data={item.children} secondsSelected={secondsSelected} handleSecondsSelected={handleSecondsSelected} ratio={ratio} realPx={realPx} />
          //       : null}
          // >
          //   <li className={selected === item.name ? 'selected custom-card ' + item.name : item.name + ' custom-card'} style={style.li} onClick={() => handleSelected(item.name)}>
          //     {item.name}
          //   </li>
          // </HtmlTooltip>
        ))
      }
      {
        (inMenu || inSubMenu) ? (
          <div
          className='Meun-container-drop-panel'
          style={style.dropPanel}
          onMouseEnter={() => setInSubMenu(true)}
          onMouseLeave={() => setInSubMenu(false)}
          >
            <Seconds data={menus[1].children} secondsSelected={secondsSelected} handleSecondsSelected={handleSecondsSelected} ratio={ratio} realPx={realPx} />
          </div>
        ) : null
      }
    </ul>
  );
}

export default Index;
