import React, { useContext, useImperativeHandle, useRef, forwardRef } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { RatioContext, realPx } from '../../static/tools/common';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './swiper.css';
import growleft from '../../static/images/opt_home_growleft.png';
import growright from '../../static/images/opt_home_growright.png';
import starline from '../../static/images/opt_home_starline.png';
import growin from '../../static/images/opt_home_growin.png';
import growlogo2 from '../../static/images/opt_home_growlogo2.png';
import growlogo1 from '../../static/images/opt_home_growlogo1.png';
import growlogo3 from '../../static/images/opt_home_growlogo3.png';
import growlogo4 from '../../static/images/opt_home_growlogo4.png';
import growlogo5 from '../../static/images/opt_home_growlogo5.png';
import growlogo6 from '../../static/images/opt_home_growlogo6.png';

const BtnHandle = forwardRef(({ ratio }: any, ref) => {
    const swiper = useSwiper();
    const onPrev = () => swiper.slidePrev();
    const onNext = () => swiper.slideNext();
    useImperativeHandle(ref, () => ({
        onPrev,
        onNext
    }));
    return (
        <div>
        </div>
    )
})

function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        sHeight: {
            marginTop: realPx(ratio, 80),
            width: realPx(ratio, 1440),
            height: realPx(ratio, 408),
        },
        btn: {
            top: realPx(ratio, 152),
            width: realPx(ratio, 64),
            height: realPx(ratio, 64),
            lineHeight: realPx(ratio, 64),
            borderRadius: '50%',
        },
        cardBox: {
            margin: '0 auto',
            width: realPx(ratio, 1300),
        },
        cardItemBox: {
            paddingTop: realPx(ratio, 20),
            width: realPx(ratio, 420),
            height: realPx(ratio, 408),
            background: 'transparent'
        },
        cardItem: {
            margin: '0 auto',
            width: realPx(ratio, 380),
            height: realPx(ratio, 368),
            borderRadius: realPx(ratio, 24),
        },
        topIcon: {
            marginTop: realPx(ratio, 34),
            marginBottom: realPx(ratio, 30),
            marginLeft: realPx(ratio, 27),
            width: realPx(ratio, 34),
            height: realPx(ratio, 24),
        },
        text: {
            margin: '0 auto',
            width: realPx(ratio, 326),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 24),
            color: '#161B27'
        },
        footer: {
            bottom: realPx(ratio, 24),
            padding: `0 ${realPx(ratio, 27)}`,  
            fontSize: realPx(ratio, 18)
        },
        stars: {         
            marginTop: realPx(ratio, 7),
            width: realPx(ratio, 108),
        },
        cIcon: {
            width: realPx(ratio, 56),
        }
    };
    const btnHandleRef = useRef();
    const list = [
        {
            title: 'Jada Acosta',
            text: "The Opt 360 team has become an extension of our team. They consistently provide well-thought-out media plans that drive results. They have gone above and beyond, by solving problems and providing support in areas we weren't expecting such as tech and creative.",
            icon: growlogo1
        },
        {
            title: 'Saniya Olsen',
            text: "Opt 360's creative team has helped build engaging creative that we would not have been able to do without them. Their team solved unique client challenges that then allowed us to launch campaigns, which would not have been possible otherwise.",
            icon: growlogo2
        },
        {
            title: 'Bradley Warner',
            text: "Working with Opt360 has been a great decision.Their strategic insights and innovative approach have truly transformed marketing efforts, for our clients.From developing targeted campaigns to enhancing our online presence, they have consistently exceeded expectations.",
            icon: growlogo3
        },
        {
            title: 'Elijah Sparks',
            text: "l reached out shortly after joining a new company in 2023 when my first assignment was to replace our existing digital agency.l knew l could trust the team of experts at Opt360 to get us back on track. This is a team that understands and leads the pack in digital marketing!",
            icon: growlogo4
        },
        {
            title: 'Ronald Burns',
            text: "Working with Opt360 has been very successful, andwhat makes it different is the ability to target theexact people we want to target. We have a veryspecifc product line that we're selling, so we need tobe very precise.",
            icon: growlogo5
        },
        {
            title: 'Jakayla Golden',
            text: "Their team is not only highly skilled butalso incredibly responsive and collaborative. l couldn'tbe happier with the results and highly recommendthem to anyone seeking a trusted marketing partner.",
            icon: growlogo6
        }
    ];

    const onPrev = () => (btnHandleRef.current as any).onPrev();
    const onNext = () => (btnHandleRef.current as any).onNext();


    return (
        <div className='c-swiper' style={style.sHeight}>
            <div style={style.cardBox}>
                <img className='c-swiper-btn' alt="growleft" src={growleft} style={{ ...style.btn, left: realPx(ratio, -10) }} onClick={onPrev}/>
                <img className='c-swiper-btn' alt="growright" src={growright} style={{ ...style.btn, right: 0 }} onClick={onNext}/>
                <Swiper
                    loop
                    slidesPerView={3}
                >
                    <BtnHandle ref={btnHandleRef as any} ratio={ratio} />
                    {
                        list.map((item: any, index: number) => (
                            <SwiperSlide className='custom-card' key={index}>
                                <div style={style.cardItemBox}>
                                    <div className="card" style={style.cardItem}>
                                        <img style={style.topIcon} src={growin} alt="growin" />
                                        <div style={style.text}>{item.text}</div>
                                        <div className="card-footer" style={style.footer}>
                                            <div>
                                                <div>{item.title}</div>
                                                <img style={style.stars} src={starline} alt="stars" />
                                            </div>
                                            <img style={style.cIcon} src={item.icon} alt="cIcon" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default Index;