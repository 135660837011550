import React, { useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import './banner.css';
import { RatioContext, realPx, contactUs } from '../../../static/tools/common';
import lightLeft from '../../../static/images/opt_lightleft.png';
import lightRight from '../../../static/images/opt_lightright.png';
import bannerpeople from '../../../static/images/opt_ctvbanner.png';
import peopleBg from '../../../static/images/opt_home_bannerbg.png';
import homeBanneric1 from '../../../static/images/opt_home_banneric1.png';
import homeBanneric2 from '../../../static/images/opt_home_banneric2.png';
import homeBtnarow from '../../../static/images/opt_home_btnarow.png';
import 'animate.css';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        bHeight: {
            height: realPx(ratio, 666),
        },
        lightLeft: {
            top: 0,
            left: 0,
            width: realPx(ratio, 400),
        },
        lightRight: {
            bottom: realPx(ratio, -100),
            right: 0,
            width: realPx(ratio, 400),
        },
        topBtn: {
            padding: `${realPx(ratio, 14)} ${realPx(ratio, 15)}`,
            width: realPx(ratio, 258),
            fontSize: realPx(ratio, 16),
            borderRadius: realPx(ratio, 50),
        },
        bannerImgStuff: {
            top: realPx(ratio, 90),
            left: realPx(ratio, 380),
        },
        bannerpeople: {
            width: realPx(ratio, 500),
            zIndex: 2
        },
        peopleBg: {
            width: realPx(ratio, 526),
            marginTop: realPx(ratio, -37),
            marginLeft: realPx(ratio, 0),
        },
        homeBanneric1: {
            width: realPx(ratio, 70),
            left: realPx(ratio, 526),
            top: realPx(ratio, 0),
        },
        homeBanneric2: {
            width: realPx(ratio, 70),
            top: realPx(ratio, 436),
            left: realPx(ratio, 0),
        },
        info: {
            top: realPx(ratio, 90),
            left: realPx(ratio, 986),
        },
        topBtnIcon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 22),
        },
        word1: {
            fontSize: realPx(ratio, 56),
            marginTop: realPx(ratio, 30),
            textAlign: 'left'
        },
        word2: {
            fontSize: realPx(ratio, 56),
            marginTop: realPx(ratio, 22),
            textAlign: 'left'
        },
        word3: {
            fontSize: realPx(ratio, 16),
            marginTop: realPx(ratio, 22),
            marginLeft: 0,
            width: realPx(ratio, 520),
            color: 'rgba(255, 255, 255, 0.50)',
            lineHeight: realPx(ratio, 28)
        },
        opt: {
            marginTop: realPx(ratio, 32),
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    };
    return (
        <div className="Banner-container" style={style.bHeight}>
            <img className='bgLight' src={lightLeft} style={style.lightLeft} alt="lightLeft" />
            <img className='bgLight' src={lightRight} style={style.lightRight} alt="lightRight" />
            <div className='wow animate__fadeIn Banner-info'  style={style.bannerImgStuff}>
                <img className='bgLight' src={bannerpeople} style={style.bannerpeople} alt="bannerpeople" />
                <img className='bgLight' src={peopleBg} style={style.peopleBg} alt="peopleBg" />
                <img className='Banner-info' src={homeBanneric1} style={style.homeBanneric1} alt="homeBanneric1" />
                <img className='Banner-info' src={homeBanneric2} style={style.homeBanneric2} alt="homeBanneric2" />
            </div>
            <div className='Banner-info' style={style.info}>
                <div className='Banner-word1 wow animate__fadeInRight' style={style.word1 as any}>Drive Brand Growth</div>
                <div className='Banner-word2  wow animate__fadeInRight' style={style.word2 as any}>With Results-Driven</div>
                <div className='Banner-word2  wow animate__fadeInRight' style={style.word2 as any}>CTV Advertising</div>
                <div className='Banner-word3  wow animate__fadeInRight' style={style.word3}>Ad-supported streaming services have transformed the living room TV into a high-performance marketing channel. Unlike traditional TV delivered via cable or satellite, connected TV (CTV) makes it possible to target precise viewers with actionable campaigns — backed by real-time data to quantify results.</div>
                <div className='Banner-opt wow animate__fadeInRight' style={style.opt}>
                    <div className='custom-btn-hover' style={{ ...style.btn, marginRight: realPx(ratio, 18) }} onClick={() => contactUs(navigate)}>
                        <span>Contact us</span>
                        <span className='Banner-opt-icon' style={style.btnImgSpan}>
                            <img className='custom-btn-hover-img' src={homeBtnarow} style={style.btnImg} alt="icon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
