import React, { useContext } from 'react';
import { RatioContext, realPx } from '../../static/tools/common';
import retargeting from '../../static/images/useracquisition@2x.png';

function Index({ icon, bg, title, text }: any) {

    const ratio = useContext(RatioContext) || 1;
    const localStyle = {
        card: {
            paddingTop: realPx(ratio, 36),
            width: realPx(ratio, 360),
            height: realPx(ratio, 320),
            borderRadius: realPx(ratio, 24),
            fontSize: realPx(ratio, 12),
            textAlign: 'center',
        },
        cardImgBox: {
            width: realPx(ratio, 80),
            height: realPx(ratio, 80),
            borderRadius: '50%',
            margin: '0 auto',
            backgroundColor: '#fff',
        },
        cardImg: {
            width: realPx(ratio, 66),
            marginTop: realPx(ratio, 7),
        },
        title: {
            fontSize: realPx(ratio, 24),
            fontWeight: 'bold',
            lineHeight: realPx(ratio, 29),
        },
        text: {
            margin: '0 auto',
            width: realPx(ratio, 326),
            fontSize: realPx(ratio, 12),
            lineHeight: realPx(ratio, 18),
            color: 'rgba(22, 27, 39, 1)'
        }
    }
    return (
        <div className='custom-card' style={{ ...localStyle.card, backgroundColor: bg || '#fff' } as any}>
            <div style={localStyle.cardImgBox}>
                <img src={icon || retargeting} style={localStyle.cardImg} alt="icon" />
            </div>
            <div style={{...localStyle.title, marginTop: realPx(ratio, 36)}}>{title[0] || '默认'}</div>
            <div style={{...localStyle.title, marginBottom: realPx(ratio, 16)}}>{title[1] || '默认'}</div>
            <div style={localStyle.text}>{text[0] || '默认'}</div>
            <div style={localStyle.text}>{text[1] || '默认'}</div>
        </div>
    )
}

export default Index;