import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './banner.css';
import { RatioContext, realPx, contactUs } from '../../../static/tools/common';
import lightLeft from '../../../static/images/opt_lightleft.png';
import lightRight from '../../../static/images/opt_lightright.png';
import homeArow1 from '../../../static/images/opt_home_arow1.png';
import homeArow2 from '../../../static/images/opt_home_arow2.png';
import bannerpeople from '../../../static/images/opt_home_bannerpeople.png';
import peopleBg from '../../../static/images/opt_home_bannerbg.png';
import homeBanneric1 from '../../../static/images/opt_home_banneric1.png';
import homeBanneric2 from '../../../static/images/opt_home_banneric2.png';
import homeBannerhz from '../../../static/images/opt_home_bannerhz.png';
import homeBtnarow from '../../../static/images/opt_home_btnarow.png';
import 'animate.css';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        bHeight: {
            height: realPx(ratio, 666),
        },
        lightLeft: {
            top: 0,
            left: 0,
            width: realPx(ratio, 400),
        },
        lightRight: {
            bottom: realPx(ratio, -100),
            right: 0,
            width: realPx(ratio, 400),
        },
        topBtn: {
            padding: `${realPx(ratio, 14)} ${realPx(ratio, 15)}`,
            width: realPx(ratio, 258),
            fontSize: realPx(ratio, 16),
            borderRadius: realPx(ratio, 50),
        },
        bannerImgStuff: {
            top: realPx(ratio, 79),
            left: realPx(ratio, 908),
        },
        bannerpeople: {
            width: realPx(ratio, 617),
            zIndex: 2
        },
        peopleBg: {
            width: realPx(ratio, 526),
            marginTop: realPx(ratio, -30),
            marginLeft: realPx(ratio, 120),
        },
        homeArow1: {
            width: realPx(ratio, 256),
            left: realPx(ratio, 566),
        },
        homeArow2: {
            width: realPx(ratio, 280),
            top: realPx(ratio, 84),
            left: 0,
        },
        homeBanneric1: {
            left: realPx(ratio, 638),
            top: realPx(ratio, 352),
        },
        homeBanneric2: {
            top: realPx(ratio, -50),
            left: realPx(ratio, 113),
        },
        info: {
            top: realPx(ratio, 120),
            left: realPx(ratio, 360),
        },
        topBtnIcon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 22),
        },
        word1: {
            fontSize: realPx(ratio, 56),
            marginTop: realPx(ratio, 16),
            lineHeight: realPx(ratio, 66),
            fontWeight: 400
        },
        word2: {
            fontSize: realPx(ratio, 56),
            marginTop: realPx(ratio, 10),
            lineHeight: realPx(ratio, 66),
            textAlign: 'left'
        },
        word3: {
            fontSize: realPx(ratio, 16),
            marginTop: realPx(ratio, 22),
            width: realPx(ratio, 450),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(255, 255, 255, 0.50)'
        },
        opt: {
            marginTop: realPx(ratio, 32),
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    };
    return (
        <div className="Banner-container" style={style.bHeight}>
            <img className='bgLight' src={lightLeft} style={style.lightLeft} alt="lightLeft" />
            <img className='bgLight' src={lightRight} style={style.lightRight} alt="lightRight" />
            <div className='Banner-info' style={style.bannerImgStuff}>
                <img className='wow animate__fadeIn bgLight' src={bannerpeople} style={style.bannerpeople} alt="bannerpeople" />
                <img className='wow animate__fadeIn bgLight' src={peopleBg} style={style.peopleBg} alt="peopleBg" />
                <div className='wow animate__fadeInLeft Banner-info' style={style.homeArow2}>
                    <img className='animate__animated sharkX-inft' style={{ width: '100%' }} src={homeArow2} alt="homeArow2" />
                </div>
                <div className='wow animate__fadeInRight Banner-info' style={style.homeArow1}>
                    <img className='animate__animated sharkX-inft' style={{ width: '100%' }} src={homeArow1} alt="homeArow1" />
                </div>
                <img className='Banner-info' src={homeBanneric1} style={style.homeBanneric1} alt="homeBanneric1" />
                <img className='Banner-info' src={homeBanneric2} style={style.homeBanneric2} alt="homeBanneric2" />
            </div>
            <div className='Banner-info' style={style.info}>
                <div className='Banner-Alert wow animate__fadeInLeft' style={style.topBtn}>
                    <img src={homeBannerhz} style={style.topBtnIcon} alt="icon" />
                    <span>Top #1 Marketing Agency</span>
                </div>
                <div className='Banner-word1 wow animate__fadeInLeft' style={style.word1}>Start Advertising</div>
                <div className='Banner-word2 wow animate__fadeInLeft' style={style.word2 as any}>with Opt360</div>
                <div className='Banner-word3 wow animate__fadeInLeft' style={style.word3}>Connecting Brands to the Right Crypto Audiences Through Engaging Ad Formats</div>
                <div className='Banner-opt wow animate__fadeInLeft ' style={style.opt}>
                    <div className='custom-btn-hover' style={{ ...style.btn, marginRight: realPx(ratio, 18) }}>
                        <span>Get Started</span>
                        <span className='Banner-opt-icon' style={style.btnImgSpan}>
                            <img className='custom-btn-hover-img' src={homeBtnarow} style={style.btnImg} alt="icon" />
                        </span>
                    </div>
                    <div className='custom-btn-hover' style={style.btn} onClick={() => contactUs(navigate)}>Contact Us</div>
                </div>
            </div>
        </div>
    );
}

export default Index;
