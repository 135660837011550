import React, { useContext, useEffect, useState } from 'react';
import './home.css';
import Nav from '../../components/nav';
import Banner from './banner';
import ScrollAd from '../../components/scrollAd';
import CForm from './form';
import { RatioContext, realPx, wowinit } from '../../static/tools/common';
import Footer from '../../components/footer';
import CardSwiper from '../../components/swiper/card';
import opt_retargeting_img01 from '../../static/images/opt_about_optimg01.png';
import opt_retargeting_img02 from '../../static/images/opt_about_optimg02.png';
import opt_about_people01 from '../../static/images/opt_about_people01.png';
import opt_about_people02 from '../../static/images/opt_about_people02.png';
import opt_about_people03 from '../../static/images/opt_about_people03.png';
import opt_about_people04 from '../../static/images/opt_about_people04.png';
import proline1 from '../../static/images/opt_home_proline1.png';
import proline2 from '../../static/images/opt_home_proline2.png';
import opt_about_email from '../../static/images/opt_about_email.png';
import opt_about_local from '../../static/images/opt_about_local.png';
import about_blue_bg_left from '../../static/images/about_blue_bg_left.png';
import about_blue_bg_right from '../../static/images/about_blue_bg_right.png';


function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        home: {
            paddingTop: realPx(ratio, 100)
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        },
        hp: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 978),
        },
        HPItem1: {
            top: realPx(ratio, 151),
            left: 0,
        },
        HPItem2: {
            top: realPx(ratio, 312),
            left: realPx(ratio, 456),
        },
        HPItem3: {
            top: realPx(ratio, 404),
            right: 0,
        },
        pImg: {
            width: realPx(ratio, 400),
            height: realPx(ratio, 600),
            borderRadius: realPx(ratio, 500),
        },
        pImg2: {
            width: realPx(ratio, 325),
            height: realPx(ratio, 487),
            borderRadius: realPx(ratio, 500),
        },
        HPItemH1: {
            fontSize: realPx(ratio, 48),
        },
        HPItemH3: {
            marginTop: realPx(ratio, 24),
            width: realPx(ratio, 398),
            fontSize: realPx(ratio, 16),
            color: 'rgba(22,27,39,0.5)',
            lineHeight: realPx(ratio, 28)
        },
        ho: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 1039),
            paddingTop: realPx(ratio, 145),
        },
        hoh1: {
            marginTop: realPx(ratio, 30),
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 59),
            foontWeight: 'bold'
        },
        hoh2: {
            marginTop: realPx(ratio, 16),
            width: realPx(ratio, 573),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        hoImg: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 375),
        },
        growing: {
            paddingTop: realPx(ratio, 101),
            height: realPx(ratio, 800),
        },
        growingh1: {
            fontSize: realPx(ratio, 40),
            lineHeight: realPx(ratio, 47),
            fontWeight: 'bold'
        },
        growingh2: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        touch: {
            paddingTop: realPx(ratio, 87),
            height: realPx(ratio, 800),
        },
        touchh1: {
            fontSize: realPx(ratio, 32),
            lineHeight: realPx(ratio, 38),
            foontWeight: 'bold'
        },
        touchh2: {
            marginTop: realPx(ratio, 24),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19)
        },
        proline: {
            width: realPx(ratio, 114),
            top: realPx(ratio, 554),
            left: realPx(ratio, 17)
        },
        proyuan: {
            bottom: realPx(ratio, 453),
            width: realPx(ratio, 170),
        },
        proplante: {
            bottom: realPx(ratio, 530),
            right: 0,
            width: realPx(ratio, 156),
        },
        proline1: {
            position: 'absolute',
            width: realPx(ratio, 47),
            top: realPx(ratio, 104),
            left: realPx(ratio, 284),
        },
        proline2: {
            position: 'absolute',
            width: realPx(ratio, 47),
            right: realPx(ratio, 300),
            bottom: realPx(ratio, 109),
        },
        infoPanel: {
            margin: '0 auto',
            width: realPx(ratio, 1200),
        },
        img01: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 363),
            overflow: 'hidden',
            borderRadius: realPx(ratio, 24),
        },
        infoPanelTitle: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        infoPanelText: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(22,27,39,0.5)'
        },
        pap: {
            marginTop: realPx(ratio, 144),
            height: realPx(ratio, 651),
        },
        papH1: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        papH2: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(22,27,39,0.5)'
        },
        papImgBox: {
            width: realPx(ratio, 1200),
            marginTop: realPx(ratio, 40),
        },
        papImgBoxItem: {
            height: realPx(ratio, 360),
            borderRadius: realPx(ratio, 24),
            overflow: 'hidden'
        },
        ahtwH1: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold',
            textAlign: 'left'
        },
        ahtwH2: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(255, 255, 255, 0.50)',
            textAlign: 'left'
        },
        ahtwH1CardTitle: {
            fontSize: realPx(ratio, 18),
            lineHeight: realPx(ratio, 21),
            fontWeight: 'bold',
            textAlign: 'left'
        },
        ahtwH1CardText: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
        },
        email: {
            width: realPx(ratio, 32),
            marginRight: realPx(ratio, 20)
        },
        local: {
            width: realPx(ratio, 36),
            marginRight: realPx(ratio, 18)
        },
        about_blue_bg_left: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: realPx(ratio, 313),
        },
        about_blue_bg_right: {
            position: 'absolute',
            right: 0,
            top: 0,
            width: realPx(ratio, 347),
        }
    }

    useEffect(() => {
        wowinit();
        if (sessionStorage.getItem('opt') === 'cu') {
            console.log('touchUs', document.getElementById('touchUs')?.offsetTop)
            window.scrollTo(0, document.getElementById('touchUs')?.offsetTop as number);
            // sessionStorage.removeItem('opt');
        }
    }, [])
    return (
        <div className="Home" style={style.home}>
            <div className='Home-nav'>
                <Nav />
            </div>
            <Banner />
            <ScrollAd />
            <div>
                <div style={style.infoPanel}>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 80) }}>
                        <div>
                            <div className='wow animate__slideInLeft' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Introducing Opt360</div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelText, marginTop: realPx(ratio, 16), }}>Where Innovation Meets Excellence In Advertising Solutions.Learn</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>More About Our Journey, Mission, And Commitment To Helping</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>Businesses Thrive In The Digital Landscape.</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>Established In 2022, Opt360 Was Founded By Individuals With</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>Backgrounds In Traditional 4a Advertising. Transitioning Into The</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>Realm Of Advertising Platforms, Specifically Focusing On App</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>Promotion, Our Founders Recognized The Shifting Landscape And</div>
                            <div className='wow slideInLeft1' style={style.infoPanelText}>Opportunities Within The Mobile App Market.</div>
                        </div>
                        <div className='wow animate__slideInRight' style={style.img01}>
                            <img className='custom-card' src={opt_retargeting_img01} style={{ width: '100%' }} alt="icon" />
                        </div>
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120) }}>
                        <div className='wow animate__slideInLeft' style={style.img01}>
                            <img className='custom-card' src={opt_retargeting_img02} style={{ width: '100%' }} alt="icon" />
                        </div>
                        <div>
                            <div className='wow animate__slideInRight' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Trust is a must</div>
                            <div className='wow slideInRight1' style={{ ...style.infoPanelText, marginTop: realPx(ratio, 16), }}>We believe that in order for marketers to adopt any platform, they must</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>trust that platform. And there is a clear formula for trust in digital</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>marketing that revolves around massive data, radical transparency, and</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>control: Trust = Data x Transparency x Control</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>Opt360 was co-founded by senior executives with deep roots in search,</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>programmatic advertising, digital media, and ad verification. Our platform,</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>therefore, provides powerful attribution capabilities, real-time reporting,</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>automated optimization and built-in, always-on testing - critical features</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>that allow performance marketers to put TV back in their marketing</div>
                            <div className='wow slideInRight1' style={style.infoPanelText}>playbooks and achieve enormous success.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='people-are-power' style={style.pap}>
                <div className='wow animate__slideInLeft' style={style.papH1}>People are our power</div>
                <div className='wow animate__slideInRight' style={{ ...style.papH2, marginTop: realPx(ratio, 16) }}>Opt360 is proud to embrace diversity and inclusion (D&I) and promote these values within the company and among the communities we serve. We</div>
                <div className='wow animate__slideInRight' style={style.papH2}>recognise this means empowering our people by embracing our differences and what brings us together in a common cause.</div>
                <div className='people-are-power-img-box' style={style.papImgBox}>
                    <div className='wow slideInLeft1' style={{...style.papImgBoxItem, width: realPx(ratio, 283)}}>
                        <img className='custom-card' src={opt_about_people01} style={{height: '100%'}} alt="icon" />
                    </div>
                    <div className='wow animate__slideInLeft' style={{...style.papImgBoxItem, width: realPx(ratio, 447)}}>
                        <img className='custom-card' src={opt_about_people02} style={{height: '100%'}} alt="icon" />
                    </div>
                    <div className='wow animate__slideInRight' style={{...style.papImgBoxItem, width: realPx(ratio, 200)}}>
                        <img className='custom-card' src={opt_about_people03} style={{height: '100%'}} alt="icon" />
                    </div>
                    <div className='wow slideInRight1' style={{...style.papImgBoxItem, width: realPx(ratio, 200)}}>
                        <img className='custom-card' src={opt_about_people04} style={{height: '100%'}} alt="icon" />
                    </div>
                </div>
                <img className='wow animate__zoomIn' src={proline1} style={style.proline1 as any} alt="icon" />
                <img className='wow animate__zoomIn' src={proline2} style={style.proline2 as any} alt="icon" />
            </div>
            <div className='Home-growing-warp' style={style.growing}>
                <div className='wow animate__flipInX' style={style.growingh1}>We’re in the business of growing yours</div>
                <div className='wow flipInX-delay' style={style.growingh2}>For more than 15 years, over 140,000 companies have trusted Opt360 to drive performance they can count on.</div>
                <div className='wow animate__zoomInDown'>
                    <CardSwiper />
                </div>
            </div>
            <div id="touchUs" className='a-Home-touch-warp' style={style.touch}>
                <img src={about_blue_bg_left} style={style.about_blue_bg_left as any} alt="icon" />
                <img src={about_blue_bg_right} style={style.about_blue_bg_right as any} alt="icon" />
                <div style={{
                    position: 'absolute',
                    top: realPx(ratio, 98),
                    left: realPx(ratio, 362)
                }}>
                    <div className='wow animate__slideInLeft' style={style.ahtwH1 as any}>Get in touch with</div>
                    <div className='wow animate__slideInLeft' style={style.ahtwH1 as any}>Opt360 to learn</div>
                    <div className='wow animate__slideInLeft' style={style.ahtwH1 as any}>more</div>
                    <div className='wow slideInLeft1' style={{ ...style.ahtwH2 as any, marginTop: realPx(ratio, 16) }}>Our experts will work with you to develop unique</div>
                    <div className='wow slideInLeft1' style={style.ahtwH2 as any}>opportunities for your brand.</div>
                    <div className='wow slideInLeft2 ahtwH1-card' style={{ marginTop: realPx(ratio, 130) }}>
                        <img src={opt_about_local} style={style.local} alt="icon" />
                        <div>
                            <div style={style.ahtwH1CardTitle as any}>Unit 225-22, 2/F Mega Cube 8, Wang</div>
                            <div style={style.ahtwH1CardTitle as any}>Kwong Rd Kln Bay, Hong Kong</div>
                        </div>
                    </div>
                    <div className='wow slideInLeft2 ahtwH1-card' style={{ marginTop: realPx(ratio, 40) }}>
                        <img src={opt_about_email} style={style.email} alt="icon" />
                        <div>
                            <div style={style.ahtwH1CardTitle as any}>business@opt360.net</div>
                            <div style={style.ahtwH1CardText}>Send us your query anytime!</div>
                        </div>
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    width: realPx(ratio, 600),
                    top: realPx(ratio, 110),
                    right: realPx(ratio, 360)
                }}>
                    <CForm />
                </div>
                {/* <div style={style.touchh1}>Engage your audiences, drive conversions,</div>
                <div style={style.touchh1}>optimize performance, and repeat.</div>
                <div style={style.touchh2}>Learn how Opt360 can help your business do it all.</div>
                <TBtn name="Get in Touch" style={{ margin: '0 auto', marginTop: realPx(ratio, 40) }} /> */}
            </div>
            <Footer />
        </div>
    );
}

export default Index;
