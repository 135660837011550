import React, { useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import './banner.css';
import { RatioContext, realPx, contactUs } from '../../../static/tools/common';
import bannerpeople from '../../../static/images/opt_retargeting_banner.png';
import homeBtnarow from '../../../static/images/opt_home_btnarow.png';
import 'animate.css';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        bHeight: {
            height: realPx(ratio, 666),
        },
        bannerpeople: {
            position: "absolute",
            top: 0,
            right: realPx(ratio, 203),
            width: realPx(ratio, 926)
        },
        info: {
            top: realPx(ratio, 120),
            left: realPx(ratio, 360),
        },
        topBtnIcon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 22),
        },
        word1: {
            fontSize: realPx(ratio, 56),
            lineHeight: realPx(ratio, 66),
            textAlign: 'left'
        },
        word2: {
            fontSize: realPx(ratio, 56),
            lineHeight: realPx(ratio, 66),
            textAlign: 'left'
        },
        word3: {
            fontSize: realPx(ratio, 16),
            marginTop: realPx(ratio, 22),
            width: realPx(ratio, 485),
            color: 'rgba(22, 27, 39, 0.50)',
            lineHeight: realPx(ratio, 28)
        },
        word4: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 30),
            color: 'rgba(22, 27, 39, 1)'
        },
        opt: {
            marginTop: realPx(ratio, 32),
        },
        btn: {
            width: realPx(ratio, 249),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    };
    return (
        <div className="r-Banner-container" style={style.bHeight}>
            <img className='wow animate__zoomIn bgLight' src={bannerpeople} style={style.bannerpeople as any} alt="bannerpeople" />
            <div className='Banner-info' style={style.info}>
                <div className='Banner-word1 wow animate__fadeInLeft' style={style.word1 as any}>Find the Right</div>
                <div className='Banner-word2 wow animate__fadeInLeft' style={style.word2 as any}>Audience Faster</div>
                <div className='Banner-word3 wow fadeInLeft1' style={style.word3}>The key to profitability is testing all the audiences you should test to find those that work best for you. Madgicx has all the tools to make it happen.</div>
                <div className='Banner-word4 wow fadeInLeft2' style={{...style.word4 as any, marginTop: realPx(ratio, 16)}}><i className='word4-dont'></i><span>AI-powered predictive targeting</span></div>
                <div className='Banner-word4 wow fadeInLeft2' style={style.word4 as any}><i className='word4-dont'></i><span>The perfect setup for full-funnel targeting</span></div>
                <div className='Banner-word4 wow fadeInLeft2' style={style.word4 as any}><i className='word4-dont'></i><span>Never-before-seen performance insights</span></div>
                <div className='Banner-opt wow fadeInLeft2' style={style.opt}>
                    <div className='custom-btn-hover' style={{ ...style.btn, marginRight: realPx(ratio, 18) }} onClick={() => contactUs(navigate)}>
                        <span>Partner with Us</span>
                        <span className='Banner-opt-icon' style={style.btnImgSpan}>
                            <img className='custom-btn-hover-img' src={homeBtnarow} style={style.btnImg} alt="icon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
