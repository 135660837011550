import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import Nav from '../../components/nav';
import Banner from './banner';
import ScrollAd from '../../components/scrollAd';
import { RatioContext, realPx, wowinit, contactUs } from '../../static/tools/common';
import Footer from '../../components/footer';
import TBtn from '../../components/button/touch';
import CardSwiper from '../../components/swiper/card';
import opt_doctv_ic01 from '../../static/images/opt_doctv_ic01.png';
import opt_doctv_ic02 from '../../static/images/opt_doctv_ic02.png';
import opt_doctv_ic03 from '../../static/images/opt_doctv_ic03.png';
import opt_doctv_ic04 from '../../static/images/opt_doctv_ic04.png';
import opt_doctv_ic05 from '../../static/images/opt_doctv_ic05.png';
import opt_doctv_ic06 from '../../static/images/opt_doctv_ic06.png';
import opt_retargeting_img01 from '../../static/images/opt_ctvimg01.png';
import opt_retargeting_img02 from '../../static/images/opt_ctvimg02.png';
import opt_retargeting_img03 from '../../static/images/opt_ctvimg03.png';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        home: {
            paddingTop: realPx(ratio, 100)
        },
        growing: {
            paddingTop: realPx(ratio, 101),
            height: realPx(ratio, 800),
        },
        growingh1: {
            fontSize: realPx(ratio, 40),
            lineHeight: realPx(ratio, 47),
            fontWeight: 'bold'
        },
        growingh2: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        touch: {
            paddingTop: realPx(ratio, 87),
            height: realPx(ratio, 400),
        },
        touchh1: {
            fontSize: realPx(ratio, 32),
            lineHeight: realPx(ratio, 38),
            fontWeight: 'bold'
        },
        touchh2: {
            marginTop: realPx(ratio, 24),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19)
        },
        cardBox: {
            paddingTop: realPx(ratio, 36),
            width: realPx(ratio, 380),
            height: realPx(ratio, 320),
            borderRadius: realPx(ratio, 24),
            textAlign: 'center',
            backgroundColor: '#fff'
        },
        cardImg: {
            width: realPx(ratio, 80)
        },
        cardTitle: {
            marginTop: realPx(ratio, 24),
            marginBottom: realPx(ratio, 13),
            fontSize: realPx(ratio, 22),
            lineHeight: realPx(ratio, 26),
            fontWeight: 'bold'
        },
        cardText: {
            width: realPx(ratio, 326),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 22),
            color: 'rgba(22, 27, 39, 0.5)'
        },
        cardH1: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        cardH2: {
            marginTop: realPx(ratio, 16),
            marginBottom: realPx(ratio, 80),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 22),
            color: 'rgba(22, 27, 39, 0.5)'
        },
        infoPanel: {
            margin: '0 auto',
            width: realPx(ratio, 1200),
        },
        img01: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 363),
            overflow: 'hidden',
            borderRadius: realPx(ratio, 24),
        },
        infoPanelTitle: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        infoPanelText: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(22,27,39,0.5)'
        },
        infoPanel_link: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19),
            color: 'rgba(49, 130, 254, 1)'
        }
    }
    const cards = [
        {
            icon: opt_doctv_ic01,
            title: 'Programmatic CTV Services',
            text: 'Our people augment your team from day one to design and execute customized campaigns that are monitored and optimized in real time.',
        },
        {
            icon: opt_doctv_ic02,
            title: 'Precise Targeting',
            text: "We take targeting beyond standard demographics like location and income to narrow viewers down based on shopping habits, hobbies, and more.",
        },
        {
            icon: opt_doctv_ic03,
            title: 'Premium Inventory',
            text: "Our vast library of brand-safe inventory ensures that your ads play on the most important screen in the household: the living room TV.",
        },
        {
            icon: opt_doctv_ic04,
            title: 'Customized Attribution',
            text: 'With an evolving roster of attribution solutions customized to your goals, we illustrate the impact of your ads and how your viewers progress through the conversion funnel. ',
        },
        {
            icon: opt_doctv_ic05,
            title: 'CTV Cross-Device Retargeting',
            text: "Using cross-device retargeting, we build awareness through CTV and then drive conversions across supplementary channels like display ads and streaming audio. ",
        },
        {
            icon: opt_doctv_ic06,
            title: '24/7 Reporting',
            text: "Our reporting dashboard — with flexible APIs and custom feeds — provides real-time insight into engagement and conversions by audience segment, ROI tracking, and more.",
        }
    ]
    useEffect(() => {
        wowinit();
    }, [])
    return (
        <div className="Home" style={style.home}>
            <div className='Home-nav'>
                <Nav />
            </div>
            <Banner />
            <ScrollAd />
            <div style={{ backgroundColor: '#F0F6FB', textAlign: 'center', paddingTop: realPx(ratio, 101), paddingBottom: realPx(ratio, 101) }}>
                <div className='wow animate__slideInLeft' style={style.cardH1}>What Do Our CTV Services Include?</div>
                <div className='wow animate__slideInRight' style={style.cardH2}>We take care of everything that goes into CTV advertising, eliminating the burden on your team.</div>
                <div className='ctv-card-panel' style={{ width: realPx(ratio, 1200), height: realPx(ratio, 700) }}>
                    {
                        cards.map((item: any, index: number) => (
                            <div className="custom-card wow animate__zoomInDown" style={style.cardBox as any} key={index}>
                                <img style={style.cardImg} src={item.icon} alt="icon" />
                                <div style={style.cardTitle}>{item.title}</div>
                                <div className='ctv-cardText' style={style.cardText}>{item.text}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div style={{
                paddingTop: realPx(ratio, 100),
                paddingBottom: realPx(ratio, 120)
            }}>
                <div style={style.infoPanel}>
                    <div className='infoPanel-item'>
                        <div>
                            <div className='wow animate__slideInLeft' style={style.infoPanel_link}>CTV ADVERTISING</div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Elevate Your Brand on</div>
                            <div className='wow slideInLeft1' style={style.infoPanelTitle}>the Big Screen</div>
                            <div className='wow slideInLeft2' style={{ ...style.infoPanelText, width: realPx(ratio, 573) }}>CTV advertising places your brand on the largest screen in the household, capturing the viewer's full attention. With sight, sound, and motion, your brand comes to life — making a memorable impact in the comfort of the viewer's home.</div>
                        </div>
                        <div className='wow animate__slideInRight' style={style.img01}>
                            <img className='custom-card' src={opt_retargeting_img01} style={{ width: '100%' }} alt="icon" />
                        </div>
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120) }}>
                        <div style={style.img01} className='wow animate__slideInLeft'>
                            <img className='custom-card' src={opt_retargeting_img02} style={{ width: '100%' }} alt="icon" />
                        </div>
                        <div>
                            <div className='wow animate__slideInRight' style={style.infoPanel_link}>CTV ADVERTISING</div>
                            <div className='wow slideInRight1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Drive Advertising</div>
                            <div className='wow slideInRight1' style={style.infoPanelTitle}>Success With Data</div>
                            <div className='wow slideInRight2' style={{ ...style.infoPanelText, width: realPx(ratio, 603) }}>Because CTV is digital, it enables targeting and attribution that traditional TV advertising can’t match. This allows you to reach the right audience with personalized messages, gain insight into how each ad contributes to your campaign's success, and adapt on the fly based on what the data reveals.</div>
                        </div>
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120), marginBottom: realPx(ratio, 26) }}>
                        <div>
                            <div className='wow animate__slideInLeft' style={style.infoPanel_link}>CTV ADVERTISING</div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Deliver Seamless</div>
                            <div className='wow slideInLeft1' style={style.infoPanelTitle}>Experiences Across</div>
                            <div className='wow slideInLeft1' style={style.infoPanelTitle}>Screens and Devices</div>
                            <div className='wow slideInLeft2' style={{ ...style.infoPanelText, width: realPx(ratio, 573) }}>Our cross-device retargeting capabilities keep your audience connected across every screen. After potential customers see your ad on the living room TV, we reengage them with follow-up ads across other devices and channels, reinforcing your message and driving conversions.</div>
                        </div>
                        <div style={style.img01} className='wow animate__slideInRight'>
                            <img className='custom-card' src={opt_retargeting_img03} style={{ width: '100%' }} alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='Home-growing-warp' style={style.growing}>
                <div className='wow animate__flipInX' style={style.growingh1}>We’re in the business of growing yours</div>
                <div className='wow flipInX-delay' style={style.growingh2}>For more than 15 years, over 140,000 companies have trusted Opt360 to drive performance they can count on.</div>
                <div className='wow animate__zoomInDown'>
                    <CardSwiper />
                </div>
            </div>
            <div className='Home-touch-warp' style={style.touch}>
                <div className='wow animate__fadeInLeft' style={style.touchh1}>Engage your audiences, drive conversions,</div>
                <div className='wow animate__fadeInLeft' style={style.touchh1}>optimize performance, and repeat.</div>
                <div className='wow animate__fadeInRight' style={style.touchh2}>Learn how Opt360 can help your business do it all.</div>
                <div className="custom-btn-hover" onClick={() => contactUs(navigate)}>
                    <TBtn name="Get in Touch" style={{fontWeight: 'bold', margin: '0 auto', marginTop: realPx(ratio, 40) }} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Index;
