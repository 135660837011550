import React, { useContext, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import './home.css';
import Nav from '../../components/nav';
import Banner from './banner';
import ScrollAd from '../../components/scrollAd';
import { RatioContext, realPx, wowinit, contactUs } from '../../static/tools/common';
import Alert from '../../components/alert';
import Footer from '../../components/footer';
import TBtn from '../../components/button/touch';
import CardSwiper from '../../components/swiper/card';
import opt_retargeting_img01 from '../../static/images/opt_retargeting_img01.png';
import opt_retargeting_img02 from '../../static/images/opt_retargeting_img02.png';
import opt_retargeting_img03 from '../../static/images/opt_retargeting_im03.png';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        home: {
            paddingTop: realPx(ratio, 100)
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        },
        hp: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 978),
        },
        HPItem1: {
            top: realPx(ratio, 151),
            left: 0,
        },
        HPItem2: {
            top: realPx(ratio, 312),
            left: realPx(ratio, 456),
        },
        HPItem3: {
            top: realPx(ratio, 404),
            right: 0,
        },
        pImg: {
            width: realPx(ratio, 400),
            height: realPx(ratio, 600),
            borderRadius: realPx(ratio, 500),
        },
        pImg2: {
            width: realPx(ratio, 325),
            height: realPx(ratio, 487),
            borderRadius: realPx(ratio, 500),
        },
        HPItemH1: {
            fontSize: realPx(ratio, 48),
        },
        HPItemH3: {
            marginTop: realPx(ratio, 24),
            width: realPx(ratio, 398),
            fontSize: realPx(ratio, 16),
            color: 'rgba(22,27,39,0.5)',
            lineHeight: realPx(ratio, 28)
        },
        ho: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 1039),
            paddingTop: realPx(ratio, 145),
        },
        hoh1: {
            marginTop: realPx(ratio, 30),
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 59),
            foontWeight: 'bold'
        },
        hoh2: {
            marginTop: realPx(ratio, 16),
            width: realPx(ratio, 573),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        hoImg: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 375),
        },
        growing: {
            paddingTop: realPx(ratio, 101),
            height: realPx(ratio, 800),
        },
        growingh1: {
            fontSize: realPx(ratio, 40),
            lineHeight: realPx(ratio, 47),
            fontWeight: 'bold'
        },
        growingh2: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        touch: {
            paddingTop: realPx(ratio, 87),
            height: realPx(ratio, 400),
        },
        touchh1: {
            fontSize: realPx(ratio, 32),
            lineHeight: realPx(ratio, 38),
            foontWeight: 'bold'
        },
        touchh2: {
            marginTop: realPx(ratio, 24),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19)
        },
        proline: {
            width: realPx(ratio, 114),
            top: realPx(ratio, 554),
            left: realPx(ratio, 17)
        },
        proyuan: {
            bottom: realPx(ratio, 453),
            width: realPx(ratio, 170),
        },
        proplante: {
            bottom: realPx(ratio, 530),
            right: 0,
            width: realPx(ratio, 156),
        },
        proline1: {
            width: realPx(ratio, 47),
            bottom: realPx(ratio, 416),
            left: '-' + realPx(ratio, 54),
        },
        proline2: {
            width: realPx(ratio, 47),
            right: '-' + realPx(ratio, 54),
            bottom: realPx(ratio, 60),
        },
        infoPanel: {
            margin: '0 auto',
            width: realPx(ratio, 1200),
        },
        img01: {
            width: realPx(ratio, 537),
        },
        infoPanelTitle: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        infoPanelText: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(22,27,39,0.5)'
        }
    }
    useEffect(() => {
      wowinit();
    }, [])
    return (
        <div className="Home" style={style.home}>
            <div className='Home-nav'>
                <Nav />
            </div>
            <Banner />
            <ScrollAd />
            <div>
                <div style={style.infoPanel}>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 80) }}>
                        <div>
                            <div className='wow animate__slideInLeft'>
                            <Alert />
                            </div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Launch Your Next Best</div>
                            <div className='wow slideInLeft1' style={style.infoPanelTitle}>Audience in a Click</div>
                            <div className='wow slideInLeft2' style={{ ...style.infoPanelText, width: realPx(ratio, 573) }}>Let the machine predict the audiences you should target next to maximize profitability and immediately launch them into your account. Leverage AI-powered audiences based on an advanced BI model.</div>
                        </div>
                        <img className='wow animate__slideInRight' src={opt_retargeting_img01} style={style.img01} alt="icon" />
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120) }}>
                        <img className='wow animate__slideInLeft' src={opt_retargeting_img02} style={style.img01} alt="icon" />
                        <div>
                            <div className='wow animate__slideInRight'>
                            <Alert />
                            </div>
                            <div className='wow slideInRight1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Cover Your Entire</div>
                            <div className='wow slideInRight1' style={style.infoPanelTitle}>Marketing Funnel</div>
                            <div className='wow slideInRight2' style={{ ...style.infoPanelText, width: realPx(ratio, 603) }}>Build and deploy a full-funnel audience targeting strategy in minutes instead of days. Easily launch all the audiences you must target along the ARR funnel - Acquisition, Retargeting, and Retention, to ensure a smooth customer journey and increase your conversion rate.</div>
                        </div>
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120), marginBottom: realPx(ratio, 26) }}>
                        <div>
                            <div className='wow animate__slideInLeft'>
                            <Alert />
                            </div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Reveal Hidden</div>
                            <div className='wow slideInLeft1' style={style.infoPanelTitle}>Targeting Insights</div>
                            <div className='wow slideInLeft2' style={{ ...style.infoPanelText, width: realPx(ratio, 573) }}>What works best - CBO or ABO? Are you targeting the right locations? How should you allocate your budget across the funnel? Opt360’s comprehensive analytics tools will help you answer that with smart data unification and visualization.</div>
                        </div>
                        <img className='wow animate__slideInRight' src={opt_retargeting_img03} style={style.img01} alt="icon" />
                    </div>
                </div>
            </div>
            <div className='Home-growing-warp' style={style.growing}>
                <div className='wow animate__flipInX' style={style.growingh1}>We’re in the business of growing yours</div>
                <div className='wow flipInX-delay' style={style.growingh2}>For more than 15 years, over 140,000 companies have trusted Opt360 to drive performance they can count on.</div>
                <div className='wow animate__zoomInDown'>
                    <CardSwiper />
                </div>
            </div>
            <div className='Home-touch-warp' style={style.touch}>
                <div className='wow animate__slideInLeft' style={style.touchh1}>Engage your audiences, drive conversions,</div>
                <div className='wow slideInLeft1' style={style.touchh1}>optimize performance, and repeat.</div>
                <div className='wow slideInLeft2' style={style.touchh2}>Learn how Opt360 can help your business do it all.</div>
                <div className='custom-btn-hover' onClick={() => contactUs(navigate)}>
                    <TBtn name="Get in Touch" style={{fontWeight: 'bold',margin: '0 auto', marginTop: realPx(ratio, 40) }} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Index;
