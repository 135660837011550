import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import Nav from '../../components/nav';
import Banner from './banner';
import ScrollAd from '../../components/scrollAd';
import { RatioContext, realPx, wowinit, contactUs } from '../../static/tools/common';
import PBtn from '../../components/button/pramiry';
import ColorCard from '../../components/card/colorCardMore';
import Footer from '../../components/footer';
import TBtn from '../../components/button/touch';
import CardSwiper from '../../components/swiper/card';
import proimg1 from '../../static/images/opt_research_appmarketimg01.png';
import proimg2 from '../../static/images/opt_research_appmarketimg02.png';
import homeBtnarow from '../../static/images/opt_home_btnarow.png';
import proline from '../../static/images/opt_home_proline.png';
import proplante from '../../static/images/opt_home_proplante.png';
import proyuan from '../../static/images/opt_home_proyuan.png';
import proaipower from '../../static/images/opt_home_proaipower.png';
import proretar from '../../static/images/opt_home_proretar.png';
import prouser from '../../static/images/opt_home_prouser.png';
import proline1 from '../../static/images/opt_home_proline1.png';
import proline2 from '../../static/images/opt_home_proline2.png';
import opt_retargeting_img01 from '../../static/images/opt_ctvimg01.png';
import opt_retargeting_img02 from '../../static/images/opt_ctvimg02.png';
import opt_retargeting_img03 from '../../static/images/opt_ctvimg03.png';
import userimg01 from '../../static/images/opt_research_appmarketimg03.png';
import opt_appmarket_monw from '../../static/images/opt_appmarket_monw.png';
import opt_appmarket_provideb from '../../static/images/opt_appmarket_provideb.png';
import opt_appmarket_adoptb from '../../static/images/opt_appmarket_adoptb.png';
import prolineBlue from '../../static/images/opt_userblueline.png';
import opt_appmarket_monb from '../../static/images/opt_appmarket_monb.png';
import opt_appmarket_providew from '../../static/images/opt_appmarket_providew.png';
import opt_appmarket_adoptw from '../../static/images/opt_appmarket_adoptw.png';


function Index() {
    const navigate = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const style = {
        home: {
            paddingTop: realPx(ratio, 100)
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        },
        hp: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 978),
        },
        HPItem1: {
            top: realPx(ratio, 151),
            left: 0,
        },
        HPItem2: {
            top: realPx(ratio, 312),
            left: realPx(ratio, 456),
        },
        HPItem3: {
            top: realPx(ratio, 404),
            right: 0,
        },
        pImg: {
            width: realPx(ratio, 400),
            height: realPx(ratio, 600),
            borderRadius: realPx(ratio, 500),
            overflow: 'hidden'
        },
        pImg2: {
            width: realPx(ratio, 325),
            height: realPx(ratio, 487),
            borderRadius: realPx(ratio, 500),
            overflow: 'hidden'
        },
        HPItemH1: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56)
        },
        HPItemH3: {
            marginTop: realPx(ratio, 16),
            width: realPx(ratio, 398),
            fontSize: realPx(ratio, 16),
            fontWeight: 400,
            color: 'rgba(22,27,39,0.5)',
            lineHeight: realPx(ratio, 28)
        },
        ho: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 700),
            paddingTop: realPx(ratio, 45),
            textAlign: 'center'
        },
        hoh1: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        hoh2: {
            marginTop: realPx(ratio, 16),
            width: realPx(ratio, 1219),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(22, 27, 39, 0.50)'
        },
        hoImg: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 375),
        },
        growing: {
            paddingTop: realPx(ratio, 101),
            height: realPx(ratio, 800),
        },
        growingh1: {
            fontSize: realPx(ratio, 40),
            lineHeight: realPx(ratio, 47),
            fontWeight: 'bold'
        },
        growingh2: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        touch: {
            paddingTop: realPx(ratio, 87),
            height: realPx(ratio, 400),
        },
        touchh1: {
            fontSize: realPx(ratio, 32),
            lineHeight: realPx(ratio, 38),
            fontWeight: 'bold'
        },
        touchh2: {
            marginTop: realPx(ratio, 24),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19)
        },
        proline: {
            width: realPx(ratio, 114),
            top: realPx(ratio, 554),
            left: realPx(ratio, 17)
        },
        prolineBlue: {
            position: 'absolute',
            width: realPx(ratio, 67),
            top: realPx(ratio, 112),
            right: realPx(ratio, 799)
        },
        proyuan: {
            bottom: realPx(ratio, 453),
            width: realPx(ratio, 170),
        },
        proplante: {
            bottom: realPx(ratio, 530),
            right: 0,
            width: realPx(ratio, 156),
        },
        proline1: {
            width: realPx(ratio, 47),
            bottom: realPx(ratio, 496),
            left: '-' + realPx(ratio, 54),
        },
        proline2: {
            width: realPx(ratio, 47),
            right: '-' + realPx(ratio, 54),
            bottom: realPx(ratio, 120),
        }, infoPanel: {
            margin: '0 auto',
            width: realPx(ratio, 1200),
        },
        img01: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 363),
            overflow: 'hidden',
            borderRadius: realPx(ratio, 24),
        },
        infoPanelTitle: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        infoPanelText: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(22,27,39,0.5)'
        },
        infoPanel_link: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19),
            color: 'rgba(49, 130, 254, 1)'
        },
        targeting: {
            height: realPx(ratio, 782),
            paddingLeft: realPx(ratio, 360),
        },
        targetingLeft: {
            marginTop: realPx(ratio, 130),
        },
        targetingLeftH1: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            fontWeight: 'bold'
        },
        targetingLeftCardCommon: {
            paddingLeft: realPx(ratio, 41),
            width: realPx(ratio, 610),
            height: realPx(ratio, 180),
            borderRadius: realPx(ratio, 24)
        },
        targetingLeftCard1: {
            marginTop: realPx(ratio, 40),
        },
        targetingLeftCard2: {
            marginTop: realPx(ratio, 24),
            height: realPx(ratio, 180),
        },
        targetingLeftCardImg: {
            width: realPx(ratio, 48),
            marginRight: realPx(ratio, 40),
        },
        targetitargetingLeftCardTitle: {
            fontSize: realPx(ratio, 24),
            lineHeight: realPx(ratio, 28),
            fontWeight: 'bold'
        },
        targetitargetingLeftCardText: {
            width: realPx(ratio, 479),
            fontSize: realPx(ratio, 18),
            lineHeight: realPx(ratio, 21),
            fontWeight: 600
        },
        userimg01: {
            position: 'absolute',
            bottom: realPx(ratio, 84),
            right: realPx(ratio, 395),
            width: realPx(ratio, 481),
        },
    }
    const opt360Cards = [
        {
            bg: 'rgba(224, 241, 255, 1)',
            icon: prouser,
            title: ['Full transparency is', 'guaranteed'],
            text: ['Seamless onboarding with no hidden tech or', 'intermediary fees.'],
        },
        {
            bg: 'rgba(232, 223, 255, 1)',
            icon: proretar,
            title: ['Enjoy high', 'retention rates'],
            text: ["We deliver measurable impact and consistent results", "for brands that keep them coming back."],
        },
        {
            bg: 'rgba(224, 241, 255, 1)',
            icon: proaipower,
            title: ['Access local', 'market expertise'],
            text: ["A collection of premium inventory to be sold by", "Opt360’s CTV local market experts."],
        }
    ]

    const [targetingLeftCard1 , setTargetingLeftCard1] = useState(false);
    const [targetingLeftCard2 , setTargetingLeftCard2] = useState(false);
    const [targetingLeftCard3 , setTargetingLeftCard3] = useState(false);

    useEffect(() => {
        wowinit();
    }, [])
    return (
        <div className="Home" style={style.home}>
            <div className='Home-nav'>
                <Nav />
            </div>
            <Banner />
            <div id="nextBlock" style={{
                paddingTop: realPx(ratio, 100),
                paddingBottom: realPx(ratio, 120)
            }}>
                <div style={style.infoPanel}>
                    <div className='infoPanel-item'>
                        <div>
                            <div className='wow animate__slideInLeft' style={style.infoPanel_link}>CTV ADVERTISING</div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Gain immediate scale</div>
                            <div className='wow slideInLeft2' style={{ ...style.infoPanelText, width: realPx(ratio, 573) }}>Connect with your audience in a captive environment from the smallest to the biggest screen in the house.</div>
                        </div>

                        <div className='wow animate__slideInRight' style={style.img01}>
                            <img className='custom-card' src={opt_retargeting_img01} style={{ width: '100%' }} alt="icon" />
                        </div>
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120) }}>

                        <div className='wow animate__slideInLeft' style={style.img01}>
                            <img className='custom-card' src={opt_retargeting_img02} style={{ width: '100%' }} alt="icon" />
                        </div>
                        <div>
                            <div className='wow animate__slideInRight' style={style.infoPanel_link}>CTV ADVERTISING</div>
                            <div className='wow slideInRight1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Work with local</div>
                            <div className='wow slideInRight1' style={style.infoPanelTitle}>experts</div>
                            <div className='wow slideInRight2' style={{ ...style.infoPanelText, paddingRight: realPx(ratio, 183), width: realPx(ratio, 533) }}>Access industry-leading experts with a proven track record of delivering for brands.</div>
                        </div>
                    </div>
                    <div className='infoPanel-item' style={{ marginTop: realPx(ratio, 120), marginBottom: realPx(ratio, 26) }}>
                        <div>
                            <div className='wow animate__slideInLeft' style={style.infoPanel_link}>CTV ADVERTISING</div>
                            <div className='wow slideInLeft1' style={{ ...style.infoPanelTitle, marginTop: realPx(ratio, 16) }}>Access proprietary</div>
                            <div className='wow slideInLeft1' style={style.infoPanelTitle}>technology</div>
                            <div className='wow slideInLeft2' style={{ ...style.infoPanelText, width: realPx(ratio, 573) }}>Manage campaigns using proprietary technology that delivers results across the entire marketing funnel.</div>
                        </div>
                        <div className='wow animate__slideInRight' style={style.img01}>
                            <img className='custom-card' src={opt_retargeting_img03} style={{ width: '100%' }} alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='r-Home-Opt360-warp'>
                <div className='r-Home-Opt360' style={style.ho as any}>
                    <div className='wow animate__slideInLeft' style={style.hoh1}>CTV media portfolio, made by a</div>
                    <div className='wow animate__slideInLeft' style={style.hoh1}>publisher, for publishers</div>
                    <div className='wow animate__slideInRight' style={style.hoh2}>A custom-built portfolio of industry-leading publishers connected to Opt360’s audience engine is proven to be in high demand from global advertisers.</div>
                    <div style={{ marginTop: realPx(ratio, 48) }}>
                        <img className='animate__animated btn-pulse-star1 proline_stuff' style={style.proline1} src={proline1} alt="icon" />
                        <img className='proline_stuff' style={style.proline2} src={proline2} alt="icon" />
                        {
                            opt360Cards.map((item: any, index: number) => (
                                <div key={index} className="wow animate__zoomInDown">
                                    <ColorCard title={item.title} text={item.text} bg={item.bg} icon={item.icon} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='Home-Proactive-warp'>
                <div className='Home-Proactive' style={style.hp}>
                    <div style={style.HPItem1} >
                        <div className='wow bounceIn' style={style.pImg}>                        
                            <img className='custom-card' style={{width: '100%'}} src={proimg1} alt="icon" />
                        </div>
                        <div className='wow flipInY bg-stuff-pos'>
                            <img className='routeAni2' style={style.proline} src={proline} alt="proline" />
                        </div>
                    </div>
                    <div style={style.HPItem2} className='Home-Proactive-info'>

                        <div className='wow flipInY bg-stuff-pos' style={style.proyuan}>
                            <img className='routeAni-not-linear' style={{ width: '100%' }} src={proyuan} alt="proyuan" />
                        </div>
                        <div className='wow animate__flipInX' style={style.HPItemH1}>Grow Your App</div>
                        <div className='wow animate__flipInX' style={style.HPItemH1}>With App</div>
                        <div className='wow animate__flipInX' style={style.HPItemH1}>Marketing Expert</div>
                        <div className='wow animate__flipInX' style={style.HPItemH3}>Dig really deep into your existing clients, new brands, industry trends and market data. Opt 360's team of analytic experts and internal research tools will help you deliver unique insights, develop inspiring pitches and deliver world-class campaigns.</div>
                        <div className="custom-btn-hover" onClick={() => navigate('/About')}>
                            <PBtn name='Learn more' icon={homeBtnarow} style={{ marginTop: realPx(ratio, 32) }} />
                        </div>
                    </div>
                    <div style={style.HPItem3} >
                        <div className='wow bounceIn' style={style.pImg2}>                        
                            <img className='custom-card' style={{width: '100%'}} src={proimg2} alt="icon" />
                        </div>
                        <div className='wow flipInY bg-stuff-pos' style={style.proplante}>
                            <img className='routeAni' style={{ width: '100%' }} src={proplante} alt="proplante" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='user-targeting-warp' style={style.targeting}>
                <div style={style.targetingLeft}>
                    <div className='wow animate__slideInLeft' style={style.targetingLeftH1}>App Marketing Benefits</div>
                    <div onMouseEnter={() => setTargetingLeftCard1(true)} onMouseLeave={() => setTargetingLeftCard1(false)} className='custom-card wow slideInLeft1 targetingLeftCard targetingLeftCard1' style={{ ...style.targetingLeftCardCommon, ...style.targetingLeftCard1 }}>
                        <img style={style.targetingLeftCardImg} src={targetingLeftCard1 ? opt_appmarket_monw : opt_appmarket_monb} alt="icon" />
                        <div>
                            <div style={{ ...style.targetitargetingLeftCardText, width: realPx(ratio, 447) }}>Monetize your app by increasing your app user base to generate more revenue.</div>
                        </div>
                    </div>
                    <div onMouseEnter={() => setTargetingLeftCard2(true)} onMouseLeave={() => setTargetingLeftCard2(false)} className='custom-card wow slideInLeft2 targetingLeftCard' style={{ ...style.targetingLeftCardCommon, ...style.targetingLeftCard2 }}>
                        <img style={style.targetingLeftCardImg} src={targetingLeftCard2 ? opt_appmarket_providew : opt_appmarket_provideb} alt="icon" />
                        <div>
                            <div style={{ ...style.targetitargetingLeftCardText, width: realPx(ratio, 400) }}>Provide growth strategy to create a blueprint for your app's success.</div>
                        </div>
                    </div>
                    <div onMouseEnter={() => setTargetingLeftCard3(true)} onMouseLeave={() => setTargetingLeftCard3(false)} className='custom-card wow slideInLeft3 targetingLeftCard' style={{ ...style.targetingLeftCardCommon, ...style.targetingLeftCard2 }}>
                        <img style={style.targetingLeftCardImg} src={targetingLeftCard3 ? opt_appmarket_adoptw : opt_appmarket_adoptb} alt="icon" />
                        <div>
                            <div style={{ ...style.targetitargetingLeftCardText, width: realPx(ratio, 440) }}>Adopt a personalized approach specifically for your app to ensure it completely fits your needs.</div>
                        </div>
                    </div>
                </div>
                <img className='wow animate__slideInRight' style={style.userimg01 as any} src={userimg01} alt="icon" />
                <img className='wow animate__zoomIn' style={style.prolineBlue as any} src={prolineBlue} alt="icon" />
            </div>
            <ScrollAd mode="deep" />

            <div className='Home-growing-warp' style={style.growing}>
                <div className='wow animate__flipInX' style={style.growingh1}>We’re in the business of growing yours</div>
                <div className='wow flipInX-delay' style={style.growingh2}>For more than 15 years, over 140,000 companies have trusted Opt360 to drive performance they can count on.</div>
                <div className='wow animate__zoomInDown'>
                    <CardSwiper />
                </div>
            </div>
            <div className='Home-touch-warp' style={style.touch}>
                <div className='wow animate__slideInLeft' style={style.touchh1}>Engage your audiences, drive conversions,</div>
                <div className='wow slideInLeft1' style={style.touchh1}>optimize performance, and repeat.</div>
                <div className='wow slideInLeft2' style={style.touchh2}>Learn how Opt360 can help your business do it all.</div>
                <div className='custom-btn-hover' onClick={() => contactUs(navigate)}>
                    <TBtn name="Get in Touch" style={{ fontWeight: 'bold', margin: '0 auto', marginTop: realPx(ratio, 40) }} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Index;
