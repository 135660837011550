import { useContext } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { RatioContext, realPx } from '../../static/tools/common';
import TBtn from '../../components/button/touch';
import { fontWeight } from '@mui/system';

const { TextArea } = Input

function Index() {

    const [form] = Form.useForm();
    const ratio = useContext(RatioContext) || 1;

    const height = realPx(ratio, 64);

    const concatStr = (str: string, key: string, value: any) => {
        if (str !== '') {
          return `${str}&${key}=${value}`;
        }
        return `${key}=${value}`;
      };

    const sendEmail = (body = '') => {
        let str = concatStr('', 'subject', '通过官网发出的邮件');
        if (body !== '') {
            str = concatStr(str, 'body', body);
        }
        
        const content = `mailto:${'business@opt360.net'}?${str}`;
        const aTag = window.document.createElement('a');
        console.log('content', content)
        aTag.setAttribute('href', content);
        aTag.click();
    }

    const submitFunc = () => {
        form.validateFields().then((values: any) => {
            console.log('values', values)
            const str = `
            [First name] ${values.firstName}
            [Last name] ${values.firstName}
            [Email] ${values.email}
            [Company name] ${values.firstName}
            [Message] ${values.message}
            `
            sendEmail(str);
        }).catch((err: any) => {
            console.log('err', err)
        })
    }

    return (
        <Form form={form} layout="vertical" className='c-form'>
            <Row gutter={48}>
                <Col span={12} className='wow animate__slideInRight'>
                    <Form.Item name="firstName" label="First name" rules={[{ required: true, message: 'Please input First Name!' }]}>
                        <Input style={{ height }} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={12} className='wow animate__slideInRight'>
                    <Form.Item name="lastName" label="Last name" rules={[{ required: true, message: 'Please input Last Name!' }]}>
                        <Input style={{ height }} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={12} className='wow animate__slideInRight'>
                    <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input Last Name!' }]}>
                        <Input style={{ height }} maxLength={30} />
                    </Form.Item>
                </Col>
                <Col span={12} className='wow animate__slideInRight'>
                    <Form.Item name="companyName" label="Company name" rules={[{ required: true, message: 'Please input Last Name!' }]}>
                        <Input style={{ height }} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={24} className='wow animate__slideInRight'>
                    <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please input message!' }]}>
                        <TextArea placeholder='Type your message...' autoSize={{ minRows: 6, maxRows: 6 }} maxLength={300} />
                    </Form.Item>
                </Col>
                <Col onClick={submitFunc} span={24} className="custom-btn-hover">
                    <TBtn name="Get in Touch" style={{ width: realPx(ratio, 480), margin: '0 auto', marginTop: realPx(ratio, 40), fontWeight: 'bold' }} />
                </Col>
            </Row>
        </Form>
    )
}

export default Index;